.slick-slider {
    max-width: calc(440px * 0.9);
}
.slider--banner .slick-dots {
    /* top: -2.33rem;
    right: 6.72rem; */
    /* bottom: 0rem; */
    bottom: -1.3rem;
    /* width: fit-content; */
}
/* .slider--banner .slick-dots li {
    display: none;
    width: fit-content;
    height: unset;
    margin: 0;
}
.slider--banner .slick-dots .slick-active {
    display: block;
}
.slider--banner .slick-dots .slick-active strong {
    color: #ff3062;
} */
.slider--banner .slick-dots .slick-active button::before {
    color: #46d3ba;
}
.slider--banner .slick-slide {
    padding: 0 0.5rem;
}
.slider--banner .slick-slide img {
    opacity: 0.3;
}
.slider--banner .slick-active img {
    opacity: 1;
    -wetbki-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
.slider--search {
    max-width: 100%;
}
.slick-prev,
.slick-next {
    z-index: 9;
}
/* .slick-prev:before,
.slick-next:before {
    display: none;
} */
.slick-prev {
    left: 15px;
}
.slick-next {
    right: 15px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background: rgba(0, 0, 0, 0.1);
}
.slider--spotlight {
    height: 260px;
}
.slider--spotlight .slick-list,
.slider--spotlight .slick-track {
    height: 100%;
}
.slider--spotlight .slick-slide > div {
    position: relative;
    height: 100%;
}
.slider--spotlight .slick-slide > div > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: fill;
}
.slider--myreview .slick-slide div div {
    height: auto;
}
