.plip-wrapper {
    width: 100%;
}
.plip-container {
    margin: 0 auto;
    /* padding: 4rem 0; */
    /* padding-bottom: 3rem; */
    user-select: none;
    /* width: 1440px; */
}
.ant-table {
    margin-bottom: 8px;
    margin-top: 8px;
}
.ant-table table > thead > tr > th {
    background-color: #f8f8f8;
    color: rgba(0, 0, 0, 0.88);
    font-size: calc(1rem + 0.5px);
    font-weight: 600;
}
.ant-table table > thead > tr > th,
.ant-table table > tbody > tr > td {
    padding: 12px !important;
}
.ant-table table > thead > tr > th:first-child,
.ant-table table > tbody > tr > td:first-child {
    padding-left: 20px !important;
}
.ant-table table > thead > tr.ant-table-measure-row,
.ant-table table > tbody > tr.ant-table-measure-row {
    display: none;
}
.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.chapter-title {
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 3rem;
}

.company {
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 64px;
    text-align: center;
}

.icon-box {
    display: flex;
    width: 49%;
    flex-direction: column;
    align-items: center;
}

.icon-name {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
    text-align: center;
}

.labeling-description {
    color: #595959;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    text-align: center;
}

.labeling-section {
    display: flex;
    /* gap: 1.154rem 40px; */
    flex-wrap: wrap;
    justify-content: space-between;
}

.labeling-title {
    color: #04c;
    font-size: 1.154rem;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 4px;
    text-align: center;
}

.link {
    color: #9d9d9d;
    text-decoration: underline;
}

.list {
    margin: 0;
    padding-left: 20px;
    li {
        line-height: 1.5;
        margin-bottom: 0;
    }
}

.list.sub-indent {
    padding-left: 33px;
}

.paragraph {
    margin-bottom: 2rem;
}

.paragraph-lg {
    margin-bottom: 3rem;
}
.paragraph.indent > *,
.paragraph-lg.indent > * {
    margin-left: 20px;
}

.paragraph-title {
    font-size: 1.154rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 8px;
    margin-left: 0;
    margin-top: 0;
}

.paragraph-lg .paragraph-title {
    font-size: 1.307rem;
}

.sub-indent {
    padding-left: 15px;
}

.table {
    margin-bottom: 8px;
    margin-top: 8px;
}

.table .list {
    padding-left: 1rem;
}

.table .text {
    font-weight: 400;
}

.table-of-contents {
    border: 1px solid #e8eaed;
    margin-bottom: 4rem;
    padding: 2rem;
}

.text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    white-space: pre-wrap;
}

.title,
.title-lg {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 64px;
    margin-top: 0;
    text-align: center;
}

.title-lg {
    font-size: 32px;
}

.table-of-contents > .title {
    color: #262626;
    font-size: 1.154rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
}

.table-of-contents > .content {
    display: grid;
    gap: 8px;
    margin: 0;
    /* padding-left: 17px; */
    list-style-type: square;
}

.table-of-contents > .content > li::marker {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 0.8;
}

.table-of-contents > .content > li > a {
    color: #262626;
    text-decoration: none;
}

.list.emphasis,
.text.emphasis {
    font-weight: 600;
}
.mb-2 {
    margin-bottom: 2px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 1.154rem;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-64 {
    margin-bottom: 64px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-8 {
    margin-left: 8px;
}

.mt-0 {
    margin-top: 0;
}

.mt-4 {
    margin-top: 4px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 1.154rem;
}

.pl-5 {
    padding-left: 5px;
}

.ipp-introduce {
    border: 1px solid #e8eaed;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 100px;
    padding: 32px;
}

.ipp-introduce .row {
    display: flex;
    gap: 64px;
}

.ipp-introduce .row .chapter,
.ipp-introduce .row .clause {
    color: #262626;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
}

.ipp-introduce .row .chapter {
    font-weight: 700;
    min-width: 320px;
}

.ipp-introduce .row .clause {
    margin-bottom: 8px;
}

@media (max-width: 992px) {
    .table-of-contents > .content {
        grid-template-columns: 100%;
    }
}
